/**
 * Squiggles for video accent
 */
export function Squiggles({
	className = 'w-20 h-auto',
}: {
	className?: string
}) {
	return (
		<svg
			width="81"
			height="43"
			viewBox="0 0 81 43"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M2 19L15.744 6.73604C18.6614 4.1328 23.1457 4.42415 25.7017 7.383L30.3669 12.7835C33.1839 16.0444 38.2501 16.0101 41.0227 12.7114L44.878 8.12464C47.6031 4.88238 52.5608 4.78406 55.4124 7.91574L59.9639 12.9144C62.8687 16.1045 67.9399 15.9329 70.6223 12.5537L79 2"
				stroke="#F69523"
				strokeWidth="5"
			/>
			<path
				d="M2 41L15.744 28.736C18.6614 26.1328 23.1457 26.4241 25.7017 29.383L30.3669 34.7835C33.1839 38.0444 38.2501 38.0101 41.0227 34.7114L44.878 30.1246C47.6031 26.8824 52.5608 26.7841 55.4124 29.9157L59.9639 34.9144C62.8687 38.1045 67.9399 37.9329 70.6223 34.5537L79 24"
				stroke="#F69523"
				strokeWidth="5"
			/>
		</svg>
	)
}

/**
 * Circle Grid can be used with `text-{color}` className to change color for
 * video section and tbe testimonial section.
 */
export function CircleGrid({
	className = 'w-20 h-auto text-brand',
}: {
	className?: string
}) {
	return (
		<svg className={className} viewBox="0 0 32 32" width="64" height="64">
			<path
				className="fill-current"
				d="M8.4 6.4a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm19.2 19.2a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM25.6 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM27.6 6.4a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM16 27.6a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM18 16a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM16 8.4a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM8.4 25.6a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM6.4 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
			/>
		</svg>
	)
}

/**
 * Quote for testimonial section
 */
export function Quote({ className = 'w-20 h-auto' }: { className?: string }) {
	return (
		<svg
			width="68"
			height="53"
			viewBox="0 0 68 53"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M65.3116 0V3.88772C59.8295 5.8977 55.5333 9.07136 52.4233 13.4087C49.3132 17.6931 47.7581 22.1627 47.7581 26.8174C47.7581 27.8224 47.9163 28.5629 48.2326 29.0389C48.4434 29.3563 48.6806 29.515 48.9442 29.515C49.2078 29.515 49.6031 29.3298 50.1302 28.9596C51.8171 27.743 53.9519 27.1347 56.5349 27.1347C59.5395 27.1347 62.2016 28.3777 64.5209 30.8638C66.8403 33.2969 68 36.2061 68 39.5913C68 43.1352 66.6558 46.256 63.9674 48.9536C61.3318 51.6512 58.1163 53 54.3209 53C49.893 53 46.0713 51.1751 42.8558 47.5254C39.6403 43.8757 38.0326 38.983 38.0326 32.8473C38.0326 25.7066 40.2202 19.3064 44.5953 13.6467C48.9705 7.98703 55.876 3.43812 65.3116 0ZM27.2791 0V3.88772C21.7969 5.8977 17.5008 9.07136 14.3907 13.4087C11.2806 17.6931 9.72558 22.1627 9.72558 26.8174C9.72558 27.8224 9.88372 28.5629 10.2 29.0389C10.4109 29.3563 10.6481 29.515 10.9116 29.515C11.1752 29.515 11.5705 29.3298 12.0977 28.9596C13.7845 27.743 15.9194 27.1347 18.5023 27.1347C21.507 27.1347 24.169 28.3777 26.4884 30.8638C28.8078 33.2969 29.9674 36.2061 29.9674 39.5913C29.9674 43.1352 28.6233 46.256 25.9349 48.9536C23.2992 51.6512 20.0837 53 16.2884 53C11.8605 53 8.03876 51.1751 4.82326 47.5254C1.60775 43.8757 0 38.983 0 32.8473C0 25.7066 2.1876 19.3064 6.56279 13.6467C10.938 7.98703 17.8434 3.43812 27.2791 0Z"
				fill="#1F1F1F"
			/>
		</svg>
	)
}
