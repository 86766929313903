import {
	CurrencyDollarIcon,
	GlobeAltIcon,
	RocketLaunchIcon,
	ShieldCheckIcon,
	WrenchScrewdriverIcon,
} from '@heroicons/react/20/solid'
import { type MetaFunction } from '@remix-run/node'
import { useEffect, useRef } from 'react'
import wizworldLogo from '#app/assets/wizworld-head.png'
import Faq from '#app/components/faq.tsx'
import { CircleGrid, Squiggles } from '#app/components/graphics.tsx'
import { Testimonials } from '#app/components/testimonials.tsx'
import { Button } from '#app/components/ui/button.tsx'
import { type RootLoaderData } from '#app/root.tsx'

export const meta: MetaFunction = ({ matches }) => {
	const root = matches[0] as unknown as RootLoaderData
	return [
		{ title: 'WizWorld: Get Your Biggest Refund This Tax Season' },
		{
			name: 'description',
			content: `Pick your favorite professional and get your maximum refund. It’s that simple!`,
		},

		{ property: 'twitter:card', content: 'summary_large_image' },
		{ property: 'twitter:site', content: '@taxxwiz' },
		{ property: 'twitter:creator', content: '@taxxwiz' },
		{ property: 'og:image:width', content: '1200' },
		{ property: 'og:image:height', content: '627' },
		{ property: 'og:image:type', content: 'image/jpg' },
		{
			property: 'og:image',
			content:
				'https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/934be4f0-9c28-4d6a-1bf9-97cf011e0500/public',
		},
		{
			property: 'og:url',
			content: `${root?.requestInfo?.origin}${root?.requestInfo?.path}`,
		},
		{
			property: 'og:site_name',
			content: 'SmartWiz',
		},
	]
}

const how_it_works = [
	{
		title: 'Complete Form and Pay Intake Fee',
		description:
			'Complete the form on this page, pay the intake fee to guarantee your quote and consultation, and instantly connect with your tax professional!',
	},
	{
		title: 'Upload Your Tax Forms',
		description:
			'You will receive a link to upload your forms to your portal!',
	},
	{
		title: 'Get Money Back in Your Pocket!',
		description:
			'We put money back where it belongs, in your pockets! Over $3,000,000 returned in tax refunds!',
	},
]

const why_choose_us = [
	{
		icon: <GlobeAltIcon />,
		color: 'bg-accent-500',
		title: 'File With a World-Class Expert',
		description:
			'Don\'t guess on your tax return - let a top professional in the industry help you get what you deserve!',
	},
	{
		icon: <WrenchScrewdriverIcon />,
		color: 'bg-brand-500',
		title: 'Make This The Easiest Tax Season of Your Life',
		description:
			'Submit your documents from the comfort of your couch, and your tax pro will handle the rest!',
	},
	{
		icon: <RocketLaunchIcon />,
		color: 'bg-brand-600',
		title: 'Get your refund FAST',
		description:
			'You will be surprised how fast you’ll receive your maximum accurate refund!',
	},
]

export default function Index() {
	const signupRef = useRef<HTMLElement>(null)

	const scrollToSignup = () => {
		if (signupRef.current) {
			signupRef.current.scrollIntoView()
		}
	}

	useEffect(() => {
		const script = document.createElement('script')
		script.src = 'https://js.hsforms.net/forms/v2.js'
		script.defer = true
		document.body.appendChild(script)

		script.addEventListener('load', () => {
			// @TS-ignore
			if (window.hbspt) {
				// @TS-ignore
				window.hbspt.forms.create({
					region: 'na1',
					portalId: '24293889',
					formId: 'ee9cb755-f9a5-4ecf-9cb3-3ec5e3e41baa',
					target: '#hubspotForm',
				})
			}
		})
	}, [])

	return (
		<main className="flex w-full flex-col gap-24">
			<section className="container flex justify-evenly gap-8">
				<div className="mt-8 flex max-w-xl flex-col items-center gap-4 lg:mt-0 lg:flex-row">
					<div>
						<p className="flex items-center gap-2 text-sm md:text-base">
							<ShieldCheckIcon className="h-4 w-4 rounded-full text-brand-500 md:h-6 md:w-6" />
							100% Satisfaction Guaranteed
						</p>
						<h1 className="mt-2 font-display text-4xl font-bold md:text-6xl">
							Get your biggest refund without tax season stress!
						</h1>
						<p className="mt-4 max-w-md text-neutral-500 md:text-lg">
							Connect with a world-class tax professional to
							get back what you deserve!
						</p>
						<Button
							size="lg"
							className="mt-4 md:text-base"
							onClick={scrollToSignup}
						>
							Get My Biggest Refund
						</Button>

						<div className="mt-6 flex items-center gap-4">
							<div className="flex -space-x-1">
								<img
									className="inline-block h-8 w-8 rounded-xl ring-4 ring-neutral-100"
									src="https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/fb3a3ab1-83cf-43e4-2b28-3ecdd9983500/small"
									alt=""
								/>
								<img
									className="inline-block h-8 w-8 rounded-xl ring-4 ring-neutral-100"
									src="https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/8fb954cb-4a1e-4d32-2d50-c45f6e092000/small"
									alt=""
								/>
								<img
									className="inline-block h-8 w-8 rounded-xl ring-4 ring-neutral-100"
									src="https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/a8a3964c-7ea9-4447-3261-64b7032ca200/small"
									alt=""
								/>
								<img
									className="inline-block h-8 w-8 rounded-xl ring-4 ring-neutral-100"
									src="https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/25f3c21b-24e2-484b-c533-68ad3c735600/small"
									alt=""
								/>
								<img
									className="inline-block h-8 w-8 rounded-xl ring-4 ring-neutral-100"
									src="https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/3e5c2da6-c1da-42de-4402-8825a462f400/small"
									alt=""
								/>
							</div>
							<p className="text-sm font-bold md:text-base">
								10,000+ Happy Clients
							</p>
						</div>
					</div>

					<div className="relative">
						<img
							src="https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/9084d64c-093c-47b3-c81c-04844a1cf400/public"
							alt="Wizworld Hero"
							className="lg:hidden"
						/>
						<div className="max-h-xl absolute -left-4 bottom-16 z-50 flex w-fit items-center gap-4 rounded-xl bg-white p-3 shadow-2xl lg:hidden">
							<CurrencyDollarIcon className="h-8 w-8 text-accent-500" />
							<div className="flex flex-col">
								<p className="text-lg font-bold">+$3,000,000</p>
								<p className="text-xs text-neutral-500">
									Given Back in Tax Returns
								</p>
							</div>
						</div>
						<div className="max-h-xl absolute -bottom-6 right-0 z-50 flex items-center gap-4 rounded-xl bg-white p-4 shadow-2xl lg:hidden">
							<img
								src={wizworldLogo}
								alt="WizWorld Head Logo"
								className="h-8 w-8"
							/>
							<div className="flex flex-col">
								<p className="font-bold leading-tight">#1 Trusted</p>
								<p className="font-bold leading-tight">Tax Software</p>
							</div>
						</div>
					</div>
				</div>

				<div className="relative hidden max-w-lg lg:block">
					<img
						src="https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/9084d64c-093c-47b3-c81c-04844a1cf400/public"
						alt="Wizworld Hero"
						className="z-10 h-full w-full"
					/>
					<div className="max-h-xl absolute -left-8 bottom-32 z-50 flex items-center gap-4 rounded-xl bg-white p-4 shadow-2xl">
						<CurrencyDollarIcon className="h-12 w-12 text-accent-500" />
						<div className="flex flex-col">
							<p className="text-xl font-bold">+$3,000,000</p>
							<p className="text-sm text-neutral-500">
								Given Back in Tax Returns
							</p>
						</div>
					</div>
					<div className="max-h-xl absolute -right-8 bottom-16 z-50 flex items-center gap-4 rounded-xl bg-white p-4 shadow-2xl">
						<img
							src={wizworldLogo}
							alt="WizWorld Head Logo"
							className="h-12 w-12"
						/>
						<div className="flex flex-col">
							<p className="text-lg font-bold leading-tight">#1 Trusted</p>
							<p className="text-lg font-bold leading-tight">Tax Software</p>
						</div>
					</div>
				</div>
			</section>

			<section className="container flex flex-col gap-8 lg:flex-row">
				<div className="flex w-full flex-col gap-8 lg:basis-1/2">
					<div className="flex flex-col items-start gap-4 font-display">
						<p className="inline-block rounded-full bg-accent-100 px-4 py-1 text-sm text-accent-600 md:text-base">
							How it works
						</p>
						<h2 className="font-display text-3xl font-bold md:text-4xl">
							Get a World-Class Tax Professional in THREE short steps
						</h2>
					</div>

					<div className="flex flex-col gap-8">
						{how_it_works.map((bullet, i) => (
							<div className="flex items-start gap-6 md:gap-8" key={`${i}`}>
								<p className="flex h-12 w-12 shrink-0 items-center justify-center rounded-xl bg-brand-100 p-4 text-2xl text-brand-500 md:h-16 md:w-16 md:text-3xl">
									{i + 1}
								</p>
								<div className="flex flex-col gap-2">
									<h3 className="font-display text-xl font-bold">
										{bullet.title}
									</h3>
									<p className="font-base text-neutral-500">
										{bullet.description}
									</p>
								</div>
							</div>
						))}

						<Button
							size="lg"
							className="self-start md:text-base"
							onClick={scrollToSignup}
						>
							Get My Biggest Refund
						</Button>
					</div>
				</div>

				<div className="relative hidden flex-1 lg:block">
					<div className="h-full w-full overflow-hidden rounded-3xl">
						<img
							src="https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/60845134-4913-43b1-c3b3-10dde1e8f800/public"
							alt="Happy Tax Season"
							className="h-full w-full object-cover"
							loading="lazy"
						/>
					</div>
					<CircleGrid className="absolute -top-8 right-8 text-brand-400" />
					<Squiggles className="absolute -left-8 bottom-8 text-brand-400" />
				</div>
			</section>

			<section className="container flex flex-col items-center gap-8">
				<h2 className="font-display text-4xl font-bold">Why Work With a World-Class Tax Preparer</h2>

				<div className="flex flex-col justify-center gap-8 lg:flex-row">
					{why_choose_us.map((bullet, i) => (
						<div
							className="flex flex-1 flex-col gap-2 rounded-xl border p-6 shadow-xl md:p-8 lg:p-12"
							key={`${i}`}
						>
							<div
								className={`h-12 w-12 rounded-xl p-3 text-white ${bullet.color}`}
							>
								{bullet.icon}
							</div>
							<h3 className="font-display text-xl font-bold">{bullet.title}</h3>
							<p className="text-sm text-neutral-500">{bullet.description}</p>
						</div>
					))}
				</div>
			</section>

			<section className="font-base container flex flex-col items-center gap-10">
				<h2 className="text-center font-display text-4xl font-bold">
					What our Customers Say
				</h2>
				<Testimonials />
			</section>

			<section className="container">
				<Faq />
			</section>

			<section
				id="signup"
				className="px-auto flex w-full flex-col items-center justify-center bg-gradient-to-b from-brand-400 via-brand-500 to-brand-600 px-8 py-16 text-center lg:p-16"
				ref={signupRef}
			>
				<h2 className="mx-auto max-w-2xl font-display text-4xl font-bold text-white">
					Are you ready to get your taxes done without stress this year?
				</h2>
				<h3 className="mt-3 font-display text-xl text-white/75">
					Fill out the form below, complete your intake fee payment and let's get started!
				</h3>
				<div
					className="form form--white relative mt-6 w-full rounded-xl text-left transition sm:max-w-xl"
					id="hubspotForm"
				></div>
			</section>
		</main>
	)
}

/**
 * ADD THIS BACK AT SOMEPOINT
 * 
 * <h2 className="font-display text-4xl font-bold text-white">
					Pick Your Tax Professional
				</h2>
				<div className="grid grid-cols-1 gap-8 lg:grid-cols-3 lg:px-16">
					{Array.from(Array(9), (e, i) => {
						return <ProfileCard key={`${i}`} />
					})}
				</div>
 */
