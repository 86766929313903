import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import useEmblaCarousel from 'embla-carousel-react'
import React from 'react'
import { CircleGrid, Quote } from './graphics.tsx' 

type TestimonialProps = {
	name?: string
	quote?: string
	image?: string
	rating?: number
	className?: string
}

export function Testimonial({
	name = 'Amber Underwood',
	quote = 'This was my first time using The Marketplace and to say the experience was great would be an understatement - it was excellent (from the service, communication, and promptness). It was just excellent. And the icing on the cake is that it was the most I ever received on a tax return. Thank you!',
	image = 'https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/3e5c2da6-c1da-42de-4402-8825a462f400/small',
	rating = 5.0,
	className = 'flex flex-col items-center lg:items-start lg:flex-row w-full h-auto text-brand grow-0 shrink-0 md:p-8',
}: TestimonialProps) {
	return (
		<div className={className}>
			<div className="relative w-64 p-8 sm:w-72 lg:w-96 lg:p-0">
				<img
					src={image}
					alt={`${name} Wizworld Testimonial`}
					className="rounded-b-full rounded-tl-full rounded-tr-[50rem]"
				/>
				<Quote className="absolute left-6 top-6 w-8 md:w-12 lg:-left-4 lg:-top-8" />
				<CircleGrid className="absolute bottom-8 w-12 fill-current text-accent-500 md:w-20 lg:-left-8 lg:bottom-8" />
			</div>
			<div className="flex w-full flex-col gap-4 p-4 md:pl-8 lg:pl-12">
				<div className="flex w-full flex-col items-center justify-between font-display lg:flex-row">
					<h3 className="text-2xl font-bold">{name}</h3>
					<div className="flex items-center gap-4">
						<StarRating rating={rating} />
						<p className="text-lg font-bold">{rating.toFixed(1)}</p>
					</div>
				</div>
				<p className="font-base flex-1 text-center leading-relaxed md:text-left">
					{quote}
				</p>
			</div>
		</div>
	)
}

const testimonials: TestimonialProps[] = [
	{
		name: 'Brenda W',
		quote:
			'This was my first time using The Marketplace and it was sooo quick and easy! I dread doing my taxes and normally use turbo tax but this was way easier and quicker and I got a much higher return than I usually get with turbo tax each year. Highly recommend!',
		image:
			'https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/3e5c2da6-c1da-42de-4402-8825a462f400/small',
		rating: 5.0,
	},
	{
		name: 'Janaea Willis',
		quote:
			'The Marketplace is very quick and efficient. As soon as you go through the questionnaire and give them your documents your tax return is ready within a few days. I file for personal and 2 different businesses. 2nd year with them and I am impressed!',
		image:
			'https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/8fb954cb-4a1e-4d32-2d50-c45f6e092000/small',
		rating: 5.0,
	},
	{
		name: 'Jay Lopez',
		quote:
			'I usually do my own taxes on Turbo Tax but being a graduate student I wanted to see if I could maximize my return. Their website is simple to utilize and walks you through every step. Health insurance, tuition forms, gains/losses from crypto or stocks is all asked for in easy steps.',
		image:
			'https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/25f3c21b-24e2-484b-c533-68ad3c735600/small',
		rating: 5.0,
	},
	{
		name: 'Jessica Dollar',
		quote:
			'This was my first time and this company truly has the whole package. They’re great communicators, knowledgeable, and friendly. I felt at ease during this process for the first time ever. Thank you!!',
		image:
			'https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/4d9f431b-c806-4d0e-81fd-d4b94798b000/small',
		rating: 5.0,
	},
	{
		name: 'Amber Underwood',
		quote:
			'This was my first time using The Marketplace and to say the experience was great would be an understatement - it was excellent (from the service, communication, and promptness). It was just excellent. And the icing on the cake is that it was the most I ever received on a tax return. Thank you!',
		image:
			'https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/c8efb024-b373-4a05-bb0e-c95210a95800/small',
		rating: 5.0,
	},
]

export function Testimonials() {
	const [emblaRef, emblaApi] = useEmblaCarousel({
		align: 'start',
		dragFree: true,
		loop: true,
	})

	function nextSlide() {
		if (emblaApi) {
			emblaApi.scrollNext()
		}
	}

	function prevSlide() {
		if (emblaApi) {
			emblaApi.scrollPrev()
		}
	}

	return (
		<div className="w-full max-w-5xl">
			<div className="relative w-full overflow-hidden" ref={emblaRef}>
				<div className="flex h-full">
					{testimonials.map(props => (
						<Testimonial key={props.name} {...props} />
					))}
				</div>
				<div className="absolute bottom-8 left-1/2 flex -translate-x-1/2 gap-2 lg:left-[22rem] lg:translate-x-0">
					<button
						className="rounded-full p-2 transition hover:bg-neutral-50 disabled:text-secondary/50"
						disabled={prevSlide === undefined}
						onClick={prevSlide}
					>
						<ArrowLeftIcon className="h-4 w-4" />
						<span className="sr-only">previous testimonial</span>
					</button>
					<button
						className="rounded-full bg-accent-500 p-2 text-white transition hover:bg-accent-400 disabled:bg-accent-200"
						disabled={nextSlide === undefined}
						onClick={nextSlide}
					>
						<ArrowRightIcon className="h-4 w-4" />
						<span className="sr-only">next testimonial</span>
					</button>
				</div>
			</div>
		</div>
	)
}

/**
 * For star rating system
 */
export function StarRating({
	rating = 4.3,
	color = 'brand',
	size = 'medium',
}: {
	rating?: number
	color?: 'brand' | 'accent'
	size?: 'small' | 'medium' | 'large'
}) {
	return (
		<div className="flex">
			{Array.from(Array(5), (e, i) => {
				return (
					<React.Fragment key={i}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							className={clsx(
								size === 'small'
									? 'h-4 w-4'
									: size === 'medium'
									? 'h-6 w-6'
									: size === 'large' ?? 'h-8 w-8',
								i < Math.floor(rating)
									? color === 'brand'
										? 'fill-brand-500'
										: 'fill-accent-500'
									: `fill-neutral-500`,
							)}
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
							/>
						</svg>
					</React.Fragment>
				)
			})}
		</div>
	)
}
